<template>
  <div class="contentWrap">
    <van-popup v-model="showAddWallet" round position="bottom">
      <van-picker
        show-toolbar
        :columns="walletList"
        @cancel="showAddWallet = false"
        @confirm="selectWallet"
      >
        <template #title>
          <van-button
            size="small"
            type="primary"
            @click="
              addWalletDialog = true;
              addWalletData = {};
            "
            >新增地址</van-button
          >
        </template>
        <template #option="item">
          <div
            style="display: flex; justify-content: space-between; width: 90%"
          >
            <p>{{ item.alias }} - {{ item.addr }}</p>
            <p @click="delWallet(item)">
              <van-icon name="delete-o" />
            </p>
          </div>
        </template>
      </van-picker>
    </van-popup>
    <van-dialog
      v-model="addWalletDialog"
      title="绑定钱包"
      show-cancel-button
      :before-close="beforeAddWallet"
    >
      <van-form>
        <van-field
          v-model="addWalletData.addr"
          label="地址"
          placeholder="地址"
        />
        <van-field
          v-model="addWalletData.alias"
          label="别名"
          placeholder="别名"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      :before-close="beforeUpload"
      v-model="uploadFileModal"
      show-cancel-button
      title="文件上传"
    >
      <van-form>
        <van-field
          readonly
          clickable
          name="picker"
          :value="orderData.amount"
          label="金额"
          placeholder="金额"
        />
        <van-field
          readonly
          clickable
          name="picker"
          :value="orderData.rate"
          label="汇率"
          placeholder="汇率"
        />
        <van-field
          v-model="addr"
          center
          clearable
          label="发送地址"
          placeholder="发送地址"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              @click="showAddWallet = true"
              >选择地址</van-button
            >
          </template>
        </van-field>

        <van-field
          clickable
          name="picker"
          :value="orderData.usdt"
          label="需打款USDT"
          placeholder="USDT"
        />
        <van-field name="uploader" label="上传文件">
          <template #input>
            <van-uploader accept="/*" v-model="files" :max-count="3" />
          </template>
        </van-field>
      </van-form>
    </van-dialog>
    <van-dialog
      v-model="USDTModal"
      title="USDT充值"
      show-cancel-button
      :before-close="confirmDepositUSDT"
      confirmButtonText="下一步"
    >
      <p style="color: red; font-size: 14px; text-align: center">
        实际金额以付款页面为主
      </p>
      <van-cell-group inset style="width: 285px">
        <van-cell :title="'汇率'" :value="depositData.rate"> </van-cell>
        <van-cell
          :title="'约需打款USDT'"
          :value="returnNum(amount / depositData.rate)"
        >
        </van-cell>
      </van-cell-group>
    </van-dialog>
    <div class="">
      <van-dialog
        v-model="orderModal"
        title="USDT充值"
        show-cancel-button
        :beforeClose="updateUSDTFiles"
        :cancelButtonColor="$returnColor().color2"
        cancelButtonText="上传文件"
      >
        <van-cell-group inset style="width: 285px">
          <van-cell :title="'金额'" :value="orderData.amount"> </van-cell>
          <van-cell :title="'汇率'" :value="orderData.rate"> </van-cell>
          <van-cell
            title="需打款USDT"
            v-clipboard:copy="String(orderData.usdt)"
            v-clipboard:success="onCopy"
          >
            <template #default>
              <p style="margin-right: 5px">
                {{ orderData.usdt }}
              </p>
            </template>
            <template #right-icon>
              <i class="far fa-clone copyIcon" style="margin-left: 5px"></i>
            </template>
          </van-cell>
          <van-cell
            v-clipboard:copy="String(orderData.usdt)"
            v-clipboard:success="onCopy"
          >
            <template #title
              ><p style="color: red; font-size: 14px">
                打款金额必须一致，否则无法到账
              </p>
            </template>
          </van-cell>

          <van-cell
            title="打款地址"
            v-clipboard:copy="String(orderData.addr)"
            v-clipboard:success="onCopy"
          >
            <template #default>
              <p style="margin-right: 5px">
                {{ orderData.addr }}
              </p>
            </template>
            <template #right-icon>
              <i class="far fa-clone copyIcon" style="margin-left: 5px"></i>
            </template>
          </van-cell>
          <van-cell :title="'备注'" :value="orderData.note"> </van-cell>
        </van-cell-group>
        <div style="display: flex; justify-content: center">
          <!-- <vue-qrcode :value="orderData.addr" :width="200" /> -->
        </div>
      </van-dialog>
    </div>
    <!-- top -->
    <van-sticky>
      <van-nav-bar @click-left="onClickLeft" title="充值申请" left-arrow>
        <template #right>
          <!-- <p @click="$router.push('/depositRecord')">申请纪录</p> -->
        </template>
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <div class="content">
      <div class="wrap">
        <div
          class="selectWrap"
          v-if="
            $store.state.selfData.can_deposit_usdt == 1 &&
            $store.state.selfData.can_deposit == 1
          "
        >
          <div :class="{ active: type == 1 }" @click="type = 1">人民币充值</div>
          <div :class="{ active: type == 2 }" @click="type = 2">USDT充值</div>
        </div>
        <van-form style="width: 100%; margin-top: 24px">
          <van-field
            v-if="type == 1 || $store.state.selfData.can_deposit_usdt != 1"
            style="border-radius: 8px 8px 0 0"
            inset
            v-model="realName"
            type="text"
            name="真实姓名"
            label="真实姓名"
            placeholder="真实姓名"
            :rules="[{ required: true, message: '请填写真实姓名' }]"
          />
          <van-field
            style="border-radius: 0 0 8px 8px"
            inset
            v-model="amount"
            type="number"
            name="充值金额"
            label="金额(人民币)"
            placeholder="充值金额"
            :rules="[{ required: true, message: '请填写充值金额' }]"
          />
          <div
            style="
              display: flex;
              justify-content: center;
              margin-top: 25px;
              flex-direction: column;
              align-items: center;
            "
          >
            <van-button
              color="#4065F0"
              @click="sendDeposit"
              style="
                height: 37px;
                width: 150px;
                border-radius: 100px;
                font-weight: bold;
              "
            >
              申请充值
            </van-button>
            <van-button
              color="rgb(205,218,250)"
              style="
                height: 37px;
                width: 150px;
                border-radius: 100px;
                font-weight: bold;
                margin-top: 12px;
              "
              @click="$router.push('/depositRecord')"
            >
              <p style="color: #4065f0 !important">申请纪录</p>
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
// import VueQrcode from "vue-qrcode";
// import { Notify } from "vant";

export default {
  components: {
    // VueQrcode,
  },
  data() {
    return {
      files: [],
      type: 1,
      realName: "",
      bankList: [],
      showAddWallet: false,
      amount: "",
      allBank: [],
      USDTModal: false,
      depositData: {},
      orderModal: false,
      orderData: {},
      uploadFileModal: false,
      addWalletData: {},
      addWalletDialog: false,
      addr: "",
      walletList: [],
      // txid: "0a771d2d57cba1ebdf13146700222ecaa9a15f18a3f672c8daed927983260ab2",
    };
  },
  methods: {
    delWallet(e) {
      const vm = this;
      this.$dialog.confirm({
        title: "删除钱包",
        message: `${e.alias}-${e.addr}`,
        beforeClose(method, done) {
          if (method == "confirm") {
            vm.$http.delete(`/mobile/wallets/${e.id}`).then((res) => {
              if (res.data.code == 0) {
                vm.$toast.success("删除成功");
                vm.getwallets();
              }
            });
          }
          done();
        },
      });
    },
    selectWallet(e) {
      this.addr = e.addr;
      this.showAddWallet = false;
    },
    beforeAddWallet(method, done) {
      if (method == "confirm") {
        this.$http.post(`/mobile/wallets`, this.addWalletData).then((res) => {
          if (res.data.code == 0) {
            this.$toast.success("成功");
            this.getwallets();
            done();
          } else {
            done(false);
          }
        });
      } else {
        done();
      }
    },
    returnNum(num) {
      return num.toFixed(2);
      // .substring(0, String(num).length - 1);
    },
    beforeUpload(method, done) {
      if (method == "confirm") {
        if (!this.addr) {
          this.$toast.fail("请输入地址");
          done(false);
          return;
        }
        var formData = new FormData();
        this.files.forEach((e) => {
          formData.append("files[]", e.file);
        });
        formData.append("addr", this.addr);
        this.$http
          .post(`/mobile/deposit/${this.orderData.order_id}/check`, formData)
          .then((res) => {
            if (res.data.code == 0) {
              this.orderModal = false;
              this.$toast.success("成功");
              done();
            } else {
              done(false);
            }
          });
      } else {
        done();
      }
    },
    updateUSDTFiles(method, done) {
      if (method == "confirm") {
        done();
      } else {
        this.files = [];
        done(false);
        this.uploadFileModal = true;
      }
    },
    onCopy() {
      this.$toast.success("已复制");
    },
    confirmDepositUSDT(method, done) {
      if (method == "confirm") {
        this.$http
          .post(`/mobile/deposit`, {
            usdt: 1,
            addr_id: this.depositData.id,
            amount: this.amount,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.orderModal = true;
              this.orderData = res.data.data;
              done();
            } else {
              done(false);
            }
          });
      } else {
        done();
      }
    },
    onClickLeft() {
      this.$router.push("/");
    },
    sendDeposit() {
      let vm = this;
      if (this.type == 2 && this.$store.state.selfData.can_deposit_usdt == 1) {
        this.$http.get(`/mobile/usdt?amount=${this.amount}`).then((res) => {
          if (res.data.code == 0) {
            this.USDTModal = true;
            this.depositData = res.data.data;
          }
        });
        return;
      }
      this.$dialog.confirm({
        title: "充值",
        message: `充值金额: ${this.amount}`,
        beforeClose(method, done) {
          if (method == "confirm") {
            vm.$http
              .post(`/mobile/deposit`, {
                amount: vm.amount,
                real_name: vm.realName,
              })
              .then((res) => {
                if (res.data.code == 0) {
                  vm.$toast.success("申请成功");
                  let link = res.data.data.pay_url;
                  const someCallback = function (url) {
                    setTimeout(() => {
                      window.open(url, "redirect");
                      location.href = link;
                    });
                  };
                  someCallback(link);
                }
              });
          }
          done();
        },
      });
    },
    getwallets() {
      this.$http.get(`/mobile/wallets`).then((res) => {
        if (res.data.code == 0) {
          this.walletList = res.data.data;
        }
      });
    },
  },
  computed: {
    selfData() {
      return this.$store.state.selfData;
    },
  },
  watch: {
    selfData() {
      if (this.selfData.can_deposit != 1) {
        this.type = 2;
      }
    },
  },
  created() {
    if (this.selfData.username) {
      if (this.selfData.can_deposit != 1) {
        this.type = 2;
      }
    }
    this.getwallets();
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  width: 90%;
}
.seletWrap {
  width: 240px;
  height: 34px;
  display: flex;
  border-radius: 50px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #fff;
  .left,
  .right {
    width: 50%;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      text-align: center;
      margin: 0;
    }
  }
  .left.active,
  .right.active {
    background: linear-gradient(to right, rgb(255, 96, 52), rgb(238, 10, 36));
    color: white;
  }
}
.content {
  // padding: 0 20px;
  display: flex;
  justify-content: center;
}
.contentWrap {
  background-color: rgba(241, 245, 254, 1);
  min-height: 100vh;
}

.selectWrap {
  width: 100%;
  display: flex;
  background-color: #4065f0;
  color: white;
  border-radius: 50px;
  height: 45px;
  margin: 0 auto;
  margin-bottom: 15px;
  div {
    width: 50%;
    // background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
      margin: 5px;
      background: white;
      border-radius: 50px;
      color: #4065f0;
      font-weight: bold;
    }
  }
}
</style>
